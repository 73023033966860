<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500 ml50 mt20">提现列表</div>
		<div class="mt20 pd20 bg-w">
			<!-- <div>
				<a-button type="primary" icon="export" @click="exportMemberAct()">导出会员</a-button>
			</div> -->
			<div class="wxb-table-gray mt20">
				<a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
					
					<!-- <div class="flex alcenter center" slot="integral" slot-scope="integral,record">
						{{integral==null ? '0' : integral.remain_integral}}
					</div> -->
			
					<template slot="action" slot-scope="record,index" v-if="record.status==0">
                        <a-button type="primary" @click="agreeCooperate(record)">
                            同意
                        </a-button>
                        <a-button type="danger" style="margin-left: 10px;" @click="refuseCooperate(record)">
                            拒绝
                        </a-button>
					</template>
				</a-table>
			</div>
		</div>
		<!-- <a-modal title="修改会员" :width="400" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					修改
				</a-button>
			</template>
			<div>
			<a-form-item label="会员昵称">
					<a-input placeholder="请输入会员姓名" v-model="member.nick_name" :maxLength="20"></a-input>
			</a-form-item>
			<a-form-item label="会员等级">
				<a-select style="width: 120px" placeholder="请选择" v-model="member.level_id">
					<a-select-option :value="-99">请选择</a-select-option>
					<a-select-option :value="0">普通会员</a-select-option>
					<a-select-option :value="item.level_id" v-for="(item,index) in level" :key="index">{{item.name}}</a-select-option>
				</a-select>
			</a-form-item>
			</div>
		</a-modal> -->
	</div>
</template>

<script>
	import { listMixin } from '../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		
		data() {
			return {
				loading: false,
				visible:false,
				editloading:false,
				confirmLoading:false,
				changePidVisibel:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					nick_name: '',
					mobile: "",
					level_id: -99,
					birthday_type:1,
					carNum:''
				},
				// level:[],
				columns: [
					{title: 'id',dataIndex: 'id',align: 'center'},
					{title: '银行卡号',dataIndex: 'bankcard',align: 'center',scopedSlots: {customRender: 'bankcard'}},
					{title: '提现金额',dataIndex: 'money',align: 'center',scopedSlots: {customRender: 'money'}},
					// {title: '联系方式',dataIndex: 'phone',align: 'center',scopedSlots: {customRender: 'phone'}},
					{title: '提交时间',dataIndex: 'add_time',align: 'center',scopedSlots: {customRender: 'add_time'},customRender: (text) => { return new Date(text * 1000).toLocaleString() }},
					{title: '状态',dataIndex: 'status',align: 'center',scopedSlots: {customRender: 'status'},customRender: (text) => { 
                        if(text==0){
                            return '待审核'
                        }else if(text==1){
                            return '打款成功'
                        }else if(text==2){
                            return '拒绝提现'
                        }
                     }},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods: {
            agreeCooperate(item){
                console.log(item);
                this.$http.api('admin/process_withdrawal',{
					status: 1,
                    id: item.id
				}).then(res=>{
				    this.getLists();
				}).catch(res=>{
					this.loading = false;
				})
            },
            refuseCooperate(item){
                console.log(item);
                this.$http.api('admin/process_withdrawal',{
					status: 2,
                    id: item.id
				}).then(res=>{
				    this.getLists();
				}).catch(res=>{
					this.loading = false;
				})
            },
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/withdrawal_list',{
					limit: this.pagination.pageSize,
					page: this.pagination.current,
				}).then(res=>{
					this.pagination.total = res.total;
					this.datas = res.list;
					// this.level=res.level;
					this.loading = false;
				}).catch(res=>{
					this.loading = false;
				})
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.member-index-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}

	.member-index-action {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		text-align: center;
		line-height: 32px;
	}
</style>
